import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { toast } from '../../core/types';

export interface ToastData {
	title: string;
	message: string;
	type?: toast;
	show?: boolean;
	progressWidth?: string;
	timeout?: number;
}

@Injectable({
	providedIn: 'root'
})
export class ToastService {
	data!: ToastData;
	public open = new Subject<ToastData>();

	show(data: ToastData): void {
		if (!data.timeout) {
			data.timeout = 3500;
		}
		if (!data.type) {
			data.type = 'success';
		}
		this.data = { ...data, show: true, progressWidth: '100%' };
		this.open.next(this.data);
	}

	hide(): void {
		this.data = { ...this.data, show: false };
		this.open.next(this.data);
	}
}
