import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { authenticationGuard } from './core/guards/authentication.guard';

const routes: Routes = [
	{ path: '', pathMatch: 'full', redirectTo: '' },
	{
		path: 'authentication',
		loadChildren: () => import('./authentication/authentication.module').then((m) => m.AuthenticationModule)
	},
	{
		path: '',
		canActivate: [authenticationGuard],
		loadChildren: () => import('./modules/home/home.module').then((m) => m.HomeModule)
	}
];

@NgModule({
	imports: [RouterModule.forRoot(routes, { scrollPositionRestoration: 'top', useHash: true })],
	exports: [RouterModule]
})
export class AppRoutingModule {}
