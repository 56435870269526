<div
	(mouseleave)="countDown()"
	class="toast_wrapper"
	[ngClass]="{
		success: toastService.data && toastService.data.type === 'success',
		warning: toastService.data && toastService.data.type === 'warning',
		danger: toastService.data && toastService.data.type === 'danger',
		info: toastService.data && toastService.data.type === 'info'
	}"
	[@openClose]="toastService.data && toastService.data.show ? 'open' : 'closed'"
>
	<div class="inner_wrapper">
		<div class="row m-0" *ngIf="toastService.data">
			<div class="col-auto vertical-align">
				<i
					*ngIf="toastService.data && toastService.data.type === 'success'"
					class="bi bi-check-circle text-success"
				></i>
				<i
					*ngIf="toastService.data && toastService.data.type === 'warning'"
					class="bi bi-exclamation-triangle-fill text-warning"
				></i>
				<i
					*ngIf="toastService.data && toastService.data.type === 'danger'"
					class="bi bi-x-circle text-danger"
				></i>
				<i
					*ngIf="toastService.data && toastService.data.type === 'info'"
					class="bi bi-info-circle text-primary"
				></i>
			</div>
			<div class="col">
				<h5 class="toast_title">
					{{ toastService.data.title }}
				</h5>
				<div class="toast_text">
					{{ toastService.data.message }}
				</div>
			</div>
			<div class="col-auto vertical-align toast_text close ml-1" (click)="close()">Cerrar</div>
		</div>
	</div>
</div>
