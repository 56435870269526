import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { StorageService } from '../utils/storage.service';

const BASE_URL = environment.apiUrl + 'administrators';

@Injectable({
	providedIn: 'root'
})
export class AuthenticationService {
	currentUser: any;

	constructor(
		private _storageService: StorageService,
		private _http: HttpClient
	) {}

	// POST METHODS
	sigIn(signInDTO: any): Observable<any> {
		const URL = `${BASE_URL}/signInReport`;
		return this._http.post<any>(URL, signInDTO);
	}
	// END POST METHODS

	// FUNCTIONS
	isAuthenticate(): boolean {
		const currentUser = this._storageService.getStorage(environment.saveLocalStorage);
		if (currentUser) {
			this.currentUser = this._storageService.getDataStorage(environment.saveLocalStorage);
			return true;
		}
		return false;
	}

	logOut(): void {
		this._storageService.removeStorage(environment.saveLocalStorage);
		window.location.reload();
	}
	// END FUNCTIONS
}
